import React from "react"
import Layout from "../../components/App/Layout"
import SEO from "../../components/App/SEO"
import Navbar from "../../components/App/Navbar"
import Footer from "../../components/App/Footer"
import { Link, graphql } from "gatsby"
import ExperienceIcoImg from "../../assets/images/ad-free/experience-ico.webp"
import ExperienceImg from "../../assets/images/ad-free/experience.webp"
import AddFreeIcoImg from "../../assets/images/ad-free/ad-free-ico.webp"
import AddFreeImg from "../../assets/images/ad-free/ad-free.webp"
import WhyIcoImg from "../../assets/images/ad-free/why-ico.webp"
import WhyImg from "../../assets/images/ad-free/why.webp"
import TestimonialsIcoImg from "../../assets/images/ad-free/testimonial-ico.webp"
import TestimonialsImg from "../../assets/images/ad-free/testimonial.webp"
import SubscriptionIcoImg from "../../assets/images/ad-free/subscription-ico.webp"
import BluemailIcoImg from "../../assets/images/ad-free/bluemail-ico.webp"
import BluemailPremiumIcoImg from "../../assets/images/ad-free/bluemailPremium-ico.webp"


const AdFree = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="Ad Free | BlueMail App"
        postDescription="BlueMail: The Ultimate Ad-Free Email Experience"
        postImage="/img/OG/og_image_experience.png"
        postURL="features-functions/ad-free"
        postSEO
      />
      <Navbar />
      <div className="features-functions ad-free">
        <div className="container pt-120">
            <div className="row align-items-center">
                <div className="col-lg-6 col-md-6 col-xs-12">
                    <img className="pb-2" src={ExperienceIcoImg} alt='Experience Ico' />
                    <h2>Experience the Clarity of BlueMail: <span>No Ads, Just Email</span></h2>
                    <p>In a digital world cluttered with pop-ups and distractions, BlueMail stands tall as a beacon of serenity. 
                        We present an email experience that’s as clear as the blue sky: completely ad-free.
                    </p>
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                    <img className='pt-4' src={ExperienceImg} alt='Experience' />
                </div>
          </div>
        </div>
        <div className="container pt-100">
            <div className="row align-items-center m-rev">
                <div className="col-lg-6 col-md-6 col-xs-12">
                    <img className='pt-4' src={AddFreeImg} alt='Add Free' />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                    <img className="pb-2" src={AddFreeIcoImg} alt='Add Free Ico' />
                    <h2>Ad-Free, <span>Always</span></h2>
                    <p>Zero Interruptions: Navigate your inbox without a single ad to divert your attention. Just you and your emails, 
                        as it should be. Clutter-Free Interface: Without ads, your inbox looks cleaner, loads faster, and operates smoother.
                    </p>
                </div>
          </div>
        </div>
        <div className="container pt-120">
            <div className="ad-free-box">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-6 col-xs-12">
                        <img className="pb-2" src={WhyIcoImg} alt='Why Ico' />
                        <h2><span>Why</span> Choose BlueMail's Ad-Free Experience?</h2>
                        <p>In an era of digital distractions, we help you concentrate on what's important. Emails without the noise. Respect 
                            for Users: We respect your time and privacy. No ads mean your content remains solely between you and the recipient. 
                            Elevated Performance: Without ads to load, access your emails quicker and enjoy a seamless user experience.
                        </p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-xs-12 text-center">
                        <img className='pt-4' src={WhyImg} alt='Why' />
                    </div>
                </div>
            </div>
        </div>
        <div className="container pt-100">
            <div className="row align-items-center m-rev">
                <div className="col-lg-6 col-md-6 col-xs-12">
                    <img className='pt-4' src={TestimonialsImg} alt='Testimonials' />
                </div>
                <div className="col-lg-6 col-md-6 col-xs-12">
                    <img className="pb-2" src={TestimonialsIcoImg} alt='Testimonials Ico' />
                    <h2><span>Testimonials</span></h2>
                    <p>Changed your mind about a sender? No worries. Adjust your sender preferences with ease through our 
                        user-friendly settings.
                    </p>
                </div>
            </div>
        </div>
        <div className="container pt-120 pb-100">
            <div className="mb-100 detailed-container">
                <div className="row align-items-center">
                    <div className="col-lg-12 col-md-12 col-xs-12 view">
                        <div className="col-lg-6 col-md-12 col-xs-12 p-2">
                            <div className="detailed-box-plans">
                                <img className="mb-2" src={SubscriptionIcoImg} alt='Subscription Ico' />
                                <h2><span>Subscription Plans</span></h2>
                                <p>A New Dawn in Emailing Awaits. Dive into BlueMail's Ad-Free Oasis. Rediscover the joy of email. 
                                    Experience BlueMail's ad-free commitment.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-6 p-2 left">
                            <div className="detailed-box">
                                <img className="mb-4" src={BluemailIcoImg} alt='Bluemail Ico' />
                                <p><span>BlueMail Basic</span> </p>
                                <p>Defend against potential threats, from phishing attacks to spam.</p>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6 col-xs-6 p-2 left">
                            <Link to='/pricing'>
                                <div className="detailed-box">
                                    <img className="mb-4" src={BluemailPremiumIcoImg} alt='Bluemail Premium Ico' />
                                    <p><span>BlueMail Premium</span> </p>
                                    <p>Delve deeper into advanced functionalities while maintaining the purity of an ad-free 
                                        inbox.</p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default AdFree

export const query = graphql`
  query AdFreePageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
